import Swiper, { Navigation, Pagination, Controller, Mousewheel } from 'swiper';

const swiper = () => {
    const commonSlider = new Swiper('.js--swiper', {
        direction: 'vertical',
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        spaceBetween: 50,
        mousewheel: {
            invert: false,
            forceToAxis: true,
            sensivity: 10,
            releaseOnEdges: true,
            thresholdDelta: 100,
        },
        slidesPerView: 1,
        modules: [Navigation, Pagination, Controller, Mousewheel],
    });

    function unwrap(wrapper) {
        const docFrag = document.createDocumentFragment();
        while (wrapper.firstChild) {
            const child = wrapper.removeChild(wrapper.firstChild);
            docFrag.appendChild(child);
        }
        wrapper.parentNode.replaceChild(docFrag, wrapper);
    }

    if (window.innerWidth < 1023) {
        commonSlider.on('destroy', () => {
            document
                .querySelector('.js--swiper')
                .classList.remove('swiper-container');
            unwrap(document.querySelector('.js--swiper .swiper-wrapper'));
            const slides = document.querySelectorAll(
                '.js--swiper .swiper-slide'
            );
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.remove('swiper-slide');
                slides[i].removeAttribute('role');
                slides[i].removeAttribute('aria-label');
                slides[i].removeAttribute('style');
            }
        });
        commonSlider.destroy(true, true);
    }
};

export default swiper;
