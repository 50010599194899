import lazyLoad from './vendors/lazyload.js';
import swiper from './vendors/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.js--lazyload')) {
        lazyLoad();
    }
    if (document.querySelector('.js--swiper')) {
        swiper();
    }
});
